import * as React from "react";
import { contactHolder } from "./contact.module.scss";
import Seo from "../components/components/Seo";

// markup
const ContactPage = () => {
   return (
      <article className={contactHolder}>
         <Seo title="Good Chaos" description="Contact Good Chaos Productions" />
         <h1 className="gambarinoHeader">Contact</h1>
         <section>
            <p>All enquiries</p>
            <p>
               <a href="mailto:info@goodchaos.co.uk">info@goodchaos.co.uk</a>
            </p>
         </section>
      </article>
   );
};

export default ContactPage;
